import { refreshToken, setAccessToken } from './Services/AuthService';
import { useEmbedded } from './Contexts/EmbeddedContext';

const useRefreshToken = () => {

    const { isEmbedded } = useEmbedded();

    const setRefreshToken = async () => {

        if (isEmbedded) {
            console.log('Embedded mode, skipping refresh token...');
            return;
        }

        const newToken = await refreshToken();
        
        if (newToken) {
            setAccessToken(newToken);
        }
    };

    return setRefreshToken;
};

export default useRefreshToken;

