import axios from 'axios';
import { bpRemoteData } from './variables';

import { refreshToken, setAccessToken, redirectLogin, setWorkspace } from '../Services/AuthService';
import i18n from './i18n';

const instance = axios.create({
    baseURL: `${bpRemoteData}/`,
});

async function tryRefreshToken() {
    try {

        const newToken = await refreshToken();

        if (newToken) {
            setAccessToken(newToken);
            return newToken;
        }

    } catch (refreshError) {
        redirectLogin();
    }
    return null;
}

async function tryRefreshTokenAndRetryRequest(error) {

    const token = await tryRefreshToken();
    
    if (token) {
        const access_token = token.access_token;
        error.config.headers['Authorization'] = `Bearer ${access_token}`;
        return instance(error.config);
    }
    
    return Promise.reject(error);
}

instance.interceptors.request.use(async config => {

    if (config.url === '/refresh/token') {
        return config;
    }

    let access_token = localStorage.getItem('access_token');

    const embedded = sessionStorage.getItem('isEmbedded') === 'true';

    const embedded_id = sessionStorage.getItem('embedded_id')

    const tokenExpiry = localStorage.getItem('expires_in');

    if (!embedded && access_token && isTokenExpired(tokenExpiry)) {        
        const token = await tryRefreshToken();
        access_token = token.access_token;
    }

    if (access_token) {
        config.headers['Authorization'] = `Bearer ${access_token}`;
    }

    if (embedded_id && config.url !== '/api/embedded') {
        config.headers['X-Embedded-ID'] = embedded_id;
    }

    config.headers['Accept-Language'] = i18n.language || 'en';

    return config;
    
}, (error) => {
    return Promise.reject(error);
});

instance.interceptors.response.use(response => response, async error => {
    
    if (error.code === 'ERR_NETWORK') {
        return;
    }

    if (error.response && error.status === 401) {
        return tryRefreshTokenAndRetryRequest(error);
    }
    
    if (error.response && error.status !== 409 && error.status !== 404) {
        redirectLogin();
    }

    return Promise.reject(error);

});

instance.interceptors.response.use((response) => {

    const refreshTokenPath = '/refresh/token';

    if (response.config.url.includes(refreshTokenPath)) {
        return response;
    }

    const language = response.headers['x-locale'] === 'pt-BR' ? 'pt' : response.headers['x-locale'];    
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
    setWorkspace(response.headers['x-workspace-id'], response.headers['x-workspace-name']);

    return response;
});

function isTokenExpired(expiry) {
    if (!expiry) return true;
    const currentTime = Date.now() / 1000;
    return currentTime > expiry;
}

export default instance;
