import { useEffect, useState } from 'react';
import useRefreshToken from './RefreshToken';

const TokenRefresher = () => {
    
    const setRefreshToken = useRefreshToken();
    const [lastRefresh, setLastRefresh] = useState(Date.now());

    useEffect(() => {

        const refreshIfNeeded = () => {

            const now = Date.now();
            const interval = 4 * 60 * 1000;

            if (lastRefresh === null || now - lastRefresh >= interval) {
                console.log('Refreshing token...');
                setRefreshToken();
                setLastRefresh(now);
            }
        };

        refreshIfNeeded();

        const intervalId = setInterval(refreshIfNeeded, 1000);

        return () => clearInterval(intervalId);

    }, [setRefreshToken, lastRefresh]);

    return null;
}

export default TokenRefresher;
